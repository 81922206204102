import React, { useState } from "react";
import "./../styles/oleg.css";
import HeaderBanner from "../components/HeaderBanner";
import { Link } from "react-router-dom";

function PaymentAccept() {
  const about = "Успешная оплата";
  return (
    <div className="ContactPage">
      <HeaderBanner headTitle={about} />
      <div className="ContentPrivacy">
        <div className="block_info">
        
            <p className="text">
                Спасибо за Ваш платеж! Средства на лицевой счет будут зачислены в течение 2 часов рабочего времени Центра. 
                В случае необходимости наш администратор свяжется с Вами. 
            </p>
            <p className="text">
                При возникновении вопросов, звоните по телефону Центра: 8(812)965-34-50, +7(921)404-10-50
            </p>
        </div>
       </div>
     

    </div>
  );
}

export default PaymentAccept;
