import React, { useState } from "react";
import "./../styles/oleg.css";
import HeaderBanner from "../components/HeaderBanner";
import { Link } from "react-router-dom";

function CreatePayment() {
  const about = "Оплата";
  const [isIncomplete, setisIncomplete] = useState(false);
  const [formData, setFormData] = useState({
    recordName: "",
    recordNamePet: "",
    recordPhone: "",
    recordEmail: "",
    recordAmount: "",
  });

  const formatPhoneNumber = (inputValue) => {
    const cleanedValue = inputValue.replace(/\D/g, "");

    let formattedValue = cleanedValue.startsWith("7")
      ? `+${cleanedValue}`
      : `+7${cleanedValue}`;

    if (formattedValue.length > 2) {
      formattedValue = `${formattedValue.substring(0, 2)} ${formattedValue.substring(2)}`;
    }
    if (formattedValue.length > 6) {
      formattedValue = `${formattedValue.substring(0, 6)} ${formattedValue.substring(6)}`;
    }
    if (formattedValue.length > 10) {
      formattedValue = `${formattedValue.substring(0, 10)} ${formattedValue.substring(10)}`;
    }
    if (formattedValue.length > 15) {
      formattedValue = `${formattedValue.substring(0, 15)} ${formattedValue.substring(15)}`;
    }

    return formattedValue;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "recordPhone") {
      const formattedValue = formatPhoneNumber(value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: formattedValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 

    try {
      const response = await fetch("https://vetfenix.ru/api/create_payment_link", { 
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Данные успешно отправлены:", data);
        window.location.href = data.message;
      } else {
        console.log("Ошибка при отправке данных:", response.status);
        alert("Ошибка при отправке данных. Пожалуйста, попробуйте еще раз.");
      }
    } catch (error) {
      console.error("Произошла ошибка при отправке данных:", error);
      alert("Произошла ошибка при отправке данных. Пожалуйста, попробуйте еще раз.");
    }
  };

  return (
    <div className="ContactPage">
      <HeaderBanner headTitle={about} />

      <div className="blockpayment">
        <div className="block" id="block3">
            <form id="record-form" className="main-form" onSubmit={handleSubmit}>
                <div className="record__form">
                <div className="record__title">Оплата услуги</div>
                <div className="record__subtitle">
                    Заполните поля и переходите к оплате
                </div>
                <div className="record__inputs">
                    <div className="record__input-group">
                    <input
                        autoComplete="off"
                        placeholder=" "
                        className="record-input"
                        required
                        name="recordName"
                        type="text"
                        value={formData.recordName}
                        onChange={handleInputChange}
                    />
                    <label className="record-label">Имя Владельца</label>
                    </div>
                    <div className="record__input-group">
                    <input
                        autoComplete="off"
                        placeholder=" "
                        className="record-input"
                        required
                        name="recordNamePet"
                        type="text"
                        value={formData.recordNamePet}
                        onChange={handleInputChange}
                    />
                    <label className="record-label">Кличка животного</label>
                    </div>
                    <div className="record__input-group">
                    <input
                        autoComplete="off"
                        placeholder=" "
                        className="record-input"
                        required
                        name="recordPhone"
                        type="tel"
                        maxLength={15}
                        value={formData.recordPhone}
                        onChange={handleInputChange}
                    />
                    <label className="record-label">Номер телефона</label>
                    {isIncomplete && <p>Пожалуйста, введите полный номер телефона.</p>}
                    </div>
                    <div className="record__input-group">
                    <input
                        autoComplete="off"
                        placeholder=" "
                        className="record-input"
                        required
                        name="recordEmail"
                        type="email"
                        value={formData.recordEmail}
                        onChange={handleInputChange}
                    />
                    <label className="record-label">Email</label>
                    </div>
                    <div className="record__input-group">
                    <input
                        autoComplete="off"
                        placeholder=" "
                        className="record-input"
                        name="recordAmount"
                        type="number"
                        min="0"
                        max="50000"
                        step="1." 
                        value={formData.recordAmount}
                        onChange={handleInputChange}
                    />
                    <label className="record-label">Сумма</label>
                    </div>
                </div>
                <div className="record__button-form">
                    <button className="record__button-submit" type="submit">
                    Перейти к оплате
                    </button>
                </div>
                <div className="record__checkbox">
                    <label>
                    <input
                        required
                        className="record__checbox-agreement-real"
                        type="checkbox"
                        name="recordCheckbox"
                    />
                    <span className="record__checbox-agreement-custom"></span>
                    Даю согласие на обработку данных
                    </label>
                </div>
                </div>
            </form>
        
        </div>
      </div>

    </div>
  );
}

export default CreatePayment;
