import React, { useState } from "react";
import "./../styles/oleg.css";
import HeaderBanner from "../components/HeaderBanner";
import { Link } from "react-router-dom";

function PaymentPage() {
    const about = "Оплата";
 

  return (
    <div className="ContactPage">
     <HeaderBanner headTitle={about} />
     <div className="ContentPrivacy">
      <div className="block_info">
          <p className="subtitle">1. Онлайн оплата услуг Ветеринарного Центра «Феникс»</p>
          <p className="text">
            Предлагаемый сервис онлайн оплаты предоставлен ООО "Банк Точка" и позволяет осуществить оплату услуг Ветеринарного Центра «Феникс» (далее – «Клиника») по безналичному расчету с использованием банковских карт.
          </p>
      </div>

      <div className="block_info">
        <p className="subtitle">2. Условия оплаты</p>
        <p className="text">
          Онлайн оплата является одним из способов исполнения обязательств по оплате услуг Клиники в рамках заключенного между Клиникой и Заказчиком (клиентом) <a href="/oferta" class="highlighted-link">договора на предоставление платных медицинских услуг</a> (далее – «Договор»). Денежные средства, перечисленные Вами с использованием сервиса онлайн оплаты, будут зачтены в счет оплаты услуг, оказываемых Клиникой по <a href="/oferta" class="highlighted-link">Договору</a> для указанного Вами пациента.
        </p>
        <p className="text">
          В случае, если Вы не являетесь стороной Договора (не являетесь Заказчиком), просим учесть, что Ваша оплата будет признана исполнением обязательства за Заказчика. В случае необходимости возврата денежных средств (по любым основаниям), такой возврат осуществляется в адрес Заказчика, если иное не будет дополнительно согласовано с Заказчиком.
        </p>
      </div>

      <div className="block_info">
        <p className="subtitle">3. Порядок проведения платежа</p>
        <p className="text">
          Для проведения платежа Вам необходимо указать фамилию, имя, отчество клиента и кличку животного, указанные в Договоре.
        </p>
        <p className="text">
          Обращаем Ваше внимание на то, что полученные денежные средства при наличии задолженности прежде всего направляются на погашение основного долга, начиная с ранее возникшей задолженности. После погашения долга оставшаяся часть денежных средств зачисляется на расчетный счет Клиники в качестве предоплаты за будущие услуги.
        </p>
      </div>

      <div className="block_info">
        <p className="subtitle">4. Способы оплаты</p>
        <p className="text">
          Вы можете произвести оплату с использованием своей банковской карты. К оплате принимаются карты Visa, MasterCard и МИР. Все транзакции защищены и зашифрованы протоколом SSL.
        </p>
      </div>

      <div className="block_info">
        <p className="subtitle">5. Сроки зачисления и безопасность</p>
        <p className="text">
          Денежные средства, перечисленные Вами с использованием сервиса онлайн оплаты, будут учтены в медицинской информационной системе Клиники в течение 3 (трех) банковских дней с момента поступления на расчетный банковский счет Клиники.
        </p>
        <p className="text">
          Данные Вашей банковской карты передаются только в зашифрованном виде и не сохраняются на нашем Web-сервере.
        </p>
      </div>

      <div className="block_info">
        <p className="subtitle">6. Контакты</p>
        <p className="text">
          Все вопросы, связанные с процессом оплаты, можно задать специалистам ООО "Банк Точка" по телефону: 8 (800) 2000 024. Вопросы, связанные с учетом поступивших на счет Клиники денежных средств, можно задать специалистам ВЦ «Феникс» по телефону: +7 (812) 9653450.
        </p>
      </div>

      <div className="block_info">  
          <Link to="/create_payment" >
            <button class="submit_payment" type="submit">оплатить</button>
          </Link>
      </div>
      
           
      </div>
    </div>
  );
}

export default PaymentPage;
