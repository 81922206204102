import React, { useState } from "react";
import "./../styles/oleg.css";
import HeaderBanner from "../components/HeaderBanner";
import { Link } from "react-router-dom";

function PaymentFail() {
  const about = "Ошибка при оплате";
  return (
    <div className="ContactPage">
      <HeaderBanner headTitle={about} />

      <div className="ContentPrivacy">
        <div className="block_info">
        
            <p className="text">
                К сожалению оплата не прошла, скорее всего были введены не верные данные карты, но воможны и другие причины
            </p>
            <p className="text">
                При возникновении вопросов, звоните по телефону Центра: 8(812)965-34-50, +7(921)404-10-50
            </p>
        </div>
       </div>
    </div>
  );
}

export default PaymentFail;
