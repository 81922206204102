import React, { useState } from "react";
import "./../styles/oleg.css";
import HeaderBanner from "../components/HeaderBanner";


function OfertaPage() {
  const about = "ДОГОВОР-ОФЕРТА";


 

  return (
    <div className="ContactPage">
     <HeaderBanner headTitle={about} />
     <div className="ContentPrivacy">
        <div className="block_info">
            <p className="subtitle">ДОГОВОР-ОФЕРТА</p>
            <p className="subtitle">1. Общие положения</p>
            <p className="text">1.1. Настоящий Договор является официальным предложением (публичной офертой) ИП Орлов Павел Георгиевич (Ветеринарный Центр «Феникс») (в дальнейшем «Исполнитель») для полностью дееспособного физического лица (далее – «Заказчик»), которое примет настоящее предложение, на указанных ниже условиях.</p>
            <p className="text">1.2. В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (ГК РФ), в случае принятия изложенных ниже условий и оплаты услуг юридическое или физическое лицо, производящее акцепт этой оферты, становится Заказчиком (в соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению Договора на условиях, изложенных в оферте).</p>
            <p className="text">1.3. Моментом полного и безоговорочного принятия Заказчиком предложения Исполнителя заключить Договор оферты (акцептом оферты) считается факт подтверждения готовности совершить оплату услуги Исполнителя, посредством нажатия кнопки «Оплатить» на сайте <a href="http://www.vetfenix.ru" class="highlighted-link">http://www.vetfenix.ru</a>. Текст настоящего Договора-оферты (далее по тексту – «Договор») расположен по адресу: <a href="https://www.vetfenix.ru/oferta" class="highlighted-link">https://www.vetfenix.ru/oferta</a>.</p>
            <p className="text">1.4. Осуществляя акцепт Договора в порядке, определенном п. 1.3 Договора, Заказчик подтверждает, что он ознакомлен, согласен, полностью и безоговорочно принимает все условия Договора в том виде, в каком они изложены в тексте Договора, в том числе в приложениях к Договору, являющихся его неотъемлемой частью.</p>
            <p className="text">1.5. Клиент согласен, что акцепт Договора в порядке, указанном в п. 1.2 Договора является заключением Договора на условиях, изложенных в нем.</p>
            <p className="text">1.6. Договор не может быть отозван.</p>
            <p className="text">1.7. Договор не требует скрепления печатями и/или подписания Заказчиком и Исполнителем (далее по тексту - Стороны) и сохраняет при этом юридическую силу.</p>
        </div>

        <div className="block_info">
            <p className="subtitle">2. Предмет договора</p>
            <p className="text">2.1. Предметом настоящего Договора является возмездное оказание Исполнителем образовательных услуг в соответствии с условиями настоящего Договора.</p>
            <p className="text">2.2. Заказчик полностью принимает условия Договора и оплачивает услуги Исполнителя в соответствии с условиями настоящего Договора.</p>
        </div>

        <div className="block_info">
            <p className="subtitle">3. Оплата Услуг</p>
            <p className="text">3.1. Стоимость услуг по Договору определяется в соответствии с действующими ценами и прописана на сайте <a href="http://www.vetfenix.ru" class="highlighted-link">http://www.vetfenix.ru</a>.</p>
            <p className="text">3.2. Стоимость услуги может быть изменена Исполнителем в одностороннем порядке.</p>
            <p className="text">3.3. Способы оплаты услуги указаны при оформлении платежа.</p>
        </div>

        <div className="block_info">
            <p className="subtitle">4. Интеллектуальная собственность</p>
            <p className="text">4.1. Вся текстовая информация и графические изображения, находящиеся на сайте <a href="http://www.vetfenix.ru" class="highlighted-link">http://www.vetfenix.ru</a> являются собственностью Исполнителя.</p>
        </div>

        <div className="block_info">
            <p className="subtitle">5. Особые условия и ответственность сторон</p>
            <p className="text">5.1. Исполнитель несет ответственность за своевременность предоставляемых услуг при выполнении Заказчиком установленных требований и правил, размещенных на сайте <a href="http://www.vetfenix.ru" class="highlighted-link">http://www.vetfenix.ru</a>.</p>
            <p className="text">5.2. Исполнитель освобождается от ответственности за нарушение условий Договора, если такое нарушение вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая: действия органов государственной власти, пожар, наводнение, землетрясение, другие стихийные действия, отсутствие электроэнергии, забастовки, гражданские волнения, беспорядки, любые иные обстоятельства, которые могут повлиять на выполнение Исполнителем Договора.</p>
            <p className="text">5.3. Исполнитель не несет ответственности за качество каналов связи общего пользования или служб, предоставляющих доступ Заказчика к его услугам.</p>
        </div>

        <div className="block_info">
            <p className="subtitle">6. Конфиденциальность и защита персональной информации</p>
            <p className="text">6.1. Исполнитель обязуется не разглашать полученную от Заказчика информацию.</p>
            <p className="text">6.2. Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми требованиями закона.</p>
            <p className="text">6.3. Исполнитель получает информацию об IP-адресе посетителя Сайта <a href="http://www.vetfenix.ru" class="highlighted-link">http://www.vetfenix.ru</a>. Данная информация не используется для установления личности посетителя.</p>
            <p className="text">6.4. Исполнитель не несет ответственности за сведения, предоставленные Заказчиком на сайте <a href="http://www.vetfenix.ru" class="highlighted-link">http://www.vetfenix.ru</a> в общедоступной форме.</p>
        </div>

        <div className="block_info">
            <p className="subtitle">7. Порядок рассмотрения претензий и споров</p>
            <p className="text">7.1. Претензии Заказчика по предоставляемым услугам принимаются Исполнителем к рассмотрению по электронной почте в течение 2 (рабочих) дней с момента возникновения спорной ситуации.</p>
            <p className="text">7.2. При рассмотрении спорных ситуаций Исполнитель вправе запросить у Заказчика всю интересующую документацию относительно рассматриваемого мероприятия. В случае непредоставления Заказчиком документов в течение 1 рабочего дня после дня требования, претензия рассмотрению Исполнителем не подлежит.</p>
            <p className="text">7.3. Исполнитель и Заказчик, принимая во внимание характер оказываемой услуги, обязуются в случае возникновения споров и разногласий, связанных с оказанием услуг, применять досудебный порядок урегулирования спора. В случае невозможности урегулирования спора в досудудебном порядке стороны вправе обратиться в суд.</p>
        </div>

        <div className="block_info">
            <p className="subtitle">8. Прочие условия</p>
            <p className="text">8.1. Заказчик обладает всеми правами и полномочиями, необходимыми для заключения и исполнения Договора.</p>
            <p className="text">8.2. Заказчик вправе в любое время в одностороннем порядке отказаться от услуг Исполнителя. В случае одностороннего отказа Заказчика от услуг Исполнителя произведенная оплата не возвращается, но может быть перенесена на другую услугу.</p>
            <p className="text">8.3. Исполнитель оставляет за собой право изменять или дополнять любые из условий настоящего Договора в любое время, опубликовывая все изменения на своем сайте.</p>
            <p className="text">8.4. По всем вопросам, не урегулированным настоящим Договором, стороны руководствуются действующим законодательством Российской Федерации.</p>
            <p className="text">8.5. Признание судом недействительности какого-либо положения настоящего Договора и правил не влечет за собой недействительность остальных положений.</p>
        </div>

        <div className="block_info">
            <p className="subtitle">9. Реквизиты Исполнителя</p>
            <p className="text">ИП Орлов Павел Георгиевич<br/>
            Св-во №3187847000088931 от 22.03.2018г<br/>
            ИНН: 781706557268<br/>
            ОГРНИП: 3187847000088931<br/>
            ОКПО: 0127901361<br/>
            Счёт (₽): 40802810803500007010<br/>
            Банк: ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"<br/>
            БИК : 044525999<br/>
            Город: Москва<br/>
            Корр. счёт: 30101810845250000999<br/>
            </p>
        </div>

        <div className="block_info">
            <p className="subtitle">10. Контактные данные</p>
            <p className="text">Санкт-Петербург, п.Металлострой, ул.Садовая, 18-17<br/>
            <a href="mailto:pavelvet@yandex.ru" class="highlighted-link">pavelvet@yandex.ru</a></p>
        </div>
    </div>
    </div>
  );
}

export default OfertaPage;
